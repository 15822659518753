<template>
  <section class="main-container">
    <p class="container-title">BERITA</p>
    <span class="block mt-1 mb-12 text-center">Informasi Seputar Sekolah</span>

    <div class="grid-container">
      <div v-for="(item, key) in news" :key="`news-${key}`">
        <div class="shadow-lg rounded bg-white max-w-xs relative mx-auto">
          <img
            :src="item.thumbnail_url_full"
            :alt="`Gambar ${item.title}`"
            class="rounded-t shadow w-full bg-green-900"
            style="min-height: 168px"
          />
          <div class="p-4 rounded-b bg-white text-gray-900 w-full">
            <p class="font-bold capitalize mb-1 text-left text-xl">
              {{ item.title }}
            </p>
            <span class="subtle-label">{{
              item.updated_at | indonesian_datetime_format
            }}</span>
            <router-link class="text-right block" :to="`/news/${item.id}`"
              >Selengkapnya</router-link
            >
          </div>
        </div>
      </div>

      <!-- trigger show all news -->
      <section class="absolute bottom-7 z-10 left-0 w-full text-center">
        <router-link
          class="tracking-wider relative z-10 font-bold inline-block rounded-lg shadow px-4 text-center bg-green-500 py-4 text-white max-w-xs"
          to="/news"
        >
          LIHAT SEMUA
          <fa-icon icon="arrow-right"></fa-icon>
        </router-link>
      </section>
      <!-- divider -->
      <div class="custom-shape-divider-bottom-1609592809">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      news: [],
    };
  },
  methods: {
    async fetchNews() {
      let response = await axios.get("api/news");
      this.news = response.data.news;
    },
  },
  mounted() {
    this.fetchNews();
  },
  name: "NewsList",
};
</script>

<style lang="scss" scoped>
.main-container {
  @apply py-28 z-10 relative bg-white;
}

.container-title {
  @apply tracking-wider text-center text-xl md:text-4xl max-w-screen-lg mx-auto container;
}

.grid-container {
  min-height: 60vh;

  @apply relative max-w-screen-lg mx-auto container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-8 pb-32 gap-8;

  &::before {
    content: " ";
    height: calc(100% - 150px);

    @apply absolute bottom-0 left-0 w-full bg-yellow-500 lg:rounded-xl;
  }
}

.custom-shape-divider-bottom-1609592809 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  // overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1609592809 svg {
  position: relative;
  display: block;
  width: calc(138% + 1.3px);
  height: 76px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1609592809 .shape-fill {
  fill: #fff;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-bottom-1609592809 svg {
    width: calc(100% + 1.3px);
    height: 76px;
  }
}
</style>